import React, { useContext, useState, useCallback, useEffect } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import {
	Stack,
	Typography,
	ListItem,
	Autocomplete,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	TextField,
	List,
	ListItemText,
	IconButton,
	Link,
	Switch,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';

import { ROLESDATA } from '../../../../components/aclContainer/roles';
import FileUploadInput from '../../../../components/fileUploadInput';
import { AuthContext } from '../../../../context';
import { LoadingContext } from '../../../../context/loading';
import { useBlobService } from '../../../../hooks/useBlobService';
import useFetchWithMsal from '../../../../hooks/useFetchWithMsal';
import { SIZE } from '../../../../hooks/useScreenSize';
import { EntryTimecardRequest } from '../../constants';

import './style.css';

function TimecardEntryFormDialog(props) {
	const { open, onClose, refreshData, editingTimecard, onTimecardDeleted } = props,
		{ httpGet, httpPost, httpPut, httpDelete } = useFetchWithMsal(),
		{ enqueueSnackbar } = useSnackbar(),
		{ startLoading, stopLoading } = useContext(LoadingContext),
		{ getUserId, canAccess } = useContext(AuthContext),
		isAdmin = canAccess([ROLESDATA.Administrator.RoleName]),
		{ uploadFiles } = useBlobService(),
		// PROJECTS
		[projectOptions, setProjectOptions] = useState([]),
		[selectedProject, setSelectedProject] = useState(null),
		[selectedFiles, setSelectedFiles] = useState([]),
		[existingAttachments, setExistingAttachments] = useState([]),
		[isBillable, setIsBillable] = useState(false),
		loadProjectOptions = useCallback(() => {
			httpGet({ urlSubPath: 'job' })
				.then(setProjectOptions)
				.catch((error) =>
					enqueueSnackbar(`Unable to load projects. ${error}`, { variant: 'error', preventDuplicate: true })
				);
		}, [enqueueSnackbar, httpGet]),
		[projectError, setProjectError] = useState(''),
		handleProjectChange = (newProject) => {
			if (newProject && newProject.id !== selectedProject?.id) {
				setSelectedProject(newProject);
				setProjectError('');
			} else if (!newProject) {
				setSelectedProject(null);
				setProjectError('Project is required');
			}
		},
		validateProject = () => {
			if (!selectedProject) {
				setProjectError('Project is required');
				return false;
			}
			return true;
		},
		// DATE
		[selectedDate, setSelectedDate] = useState(dayjs()),
		handleDateChange = (newDate) => {
			setSelectedDate(newDate);
		},
		// ACTIVITY
		[activityOptions, setActivityOptions] = useState([]),
		[selectedActivity, setSelectedActivity] = useState(null),
		[activityError, setActivityError] = useState(''),
		loadActivityOptions = useCallback(() => {
			httpGet({ urlSubPath: 'activities', queryParams: { isTimecardRelated: true } })
				.then(setActivityOptions)
				.catch((error) =>
					enqueueSnackbar(`Unable to load activities. ${error}`, { variant: 'error', preventDuplicate: true })
				);
		}, [enqueueSnackbar, httpGet]),
		handleActivityChange = (newActivity) => {
			if (newActivity && newActivity.id !== selectedActivity?.id) {
				setSelectedActivity(newActivity);
				setActivityError('');
				setIsBillable(newActivity.defaultIsBillable);
			} else if (!newActivity) {
				setSelectedActivity(null);
				setActivityError('Activity is required');
				setIsBillable(false);
			}
		},
		// HOURS
		[selectedHours, setSelectedHours] = useState(''),
		[hoursError, setHoursError] = useState(''),
		validateHours = useCallback((value) => {
			if (value === '') return 'Hours cannot be empty';
			const numValue = parseFloat(value);
			if (isNaN(numValue)) return 'Please enter a valid number';
			if (numValue === 0) return 'Hours cannot be zero';
			if (numValue >= 10) return 'Hours should be less than 10';
			if (numValue % 0.5 !== 0) return 'Hours must be in increments of 0.5 (30 minutes)';
			return '';
		}, []),
		handleHoursChange = (event) => {
			const newValue = event.target.value;
			setSelectedHours(newValue);
			setHoursError(validateHours(newValue));
		},
		// NOTES
		[selectedNotes, setSelectedNotes] = useState(''),
		handleNotesChange = (event) => {
			const newValue = event.currentTarget.value;
			setSelectedNotes(newValue);
		},
		// TIMECARD
		[id, setId] = useState(null),
		[hasLoadedOptions, setHasLoadedOptions] = useState(false),
		// Declare userOptions and related states before any references
		[userOptions, setUserOptions] = useState([]),
		[selectedUser, setSelectedUser] = useState(null),
		[userError, setUserError] = useState(''),
		loadUserOptions = useCallback(() => {
			httpGet({ urlSubPath: 'users' })
				.then((data) => {
					setUserOptions(data);
					if (!editingTimecard) {
						setSelectedUser(data.find((user) => user.id === getUserId()));
					}
				})
				.catch((error) =>
					enqueueSnackbar(`Unable to load users. ${error}`, { variant: 'error', preventDuplicate: true })
				);
		}, [enqueueSnackbar, httpGet, getUserId, editingTimecard]),
		handleUserChange = (newUser) => {
			if (newUser && newUser.id !== selectedUser?.id) {
				setSelectedUser(newUser);
				setUserError('');
			} else if (!newUser) {
				setSelectedUser(null);
				setUserError('User is required');
			}
		},
		resetTimecardForm = useCallback(() => {
			setId(null);
			setSelectedDate(dayjs());
			setSelectedProject(null);
			setSelectedActivity(null);
			setSelectedHours('');
			setSelectedNotes('');
			setSelectedFiles([]);
			setExistingAttachments([]);
			setIsBillable(false);
			setSelectedUser(null);
		}, []),
		[openConfirmationDialog, setOpenConfirmationDialog] = useState(false),
		handleCloseConfirmationDialog = () => setOpenConfirmationDialog(false),
		handleFinishSubmit = () => {
			handleCloseConfirmationDialog();
			resetTimecardForm();
			onClose();
		},
		handleSave = async (isSubmit = false) => {
			startLoading();

			try {
				// Validate project before saving
				if (!validateProject()) {
					throw new Error('Please select a project');
				}

				// Validate hours before saving
				const hoursValidationError = validateHours(selectedHours);
				if (hoursValidationError) {
					throw new Error(hoursValidationError);
				}

				let postUploadAttachments = [...existingAttachments];
				if (selectedFiles.length > 0) {
					const uploadedFiles = await uploadFiles(selectedFiles);
					postUploadAttachments = [
						...postUploadAttachments,
						...uploadedFiles.map((file) => ({
							filename: file.originalFilename,
							uri: file.url,
							bucket: file.bucket,
						})),
					];
				}

				const currentUserId = getUserId(),
					request = new EntryTimecardRequest({
						id,
						timecardDate: selectedDate.toISOString(),
						jobId: selectedProject?.id ?? null,
						activityId: selectedActivity.id,
						userId: selectedUser?.id ?? currentUserId,
						createdBy: currentUserId,
						hours: parseFloat(selectedHours),
						isBillable,
						notes: selectedNotes,
						attachments: postUploadAttachments,
						isSubmitted: isSubmit,
					});

				let response;
				if (id) {
					response = await httpPut({ urlSubPath: `timecards/${id}`, body: request.getBody() });
				} else {
					response = await httpPost({ urlSubPath: 'timecards', body: request.getBody() });
				}

				const { activity, timecardDate, job } = response,
					parsedDate = dayjs(timecardDate).format('YYYY-MM-DD');
				let message = `${isSubmit ? 'Submitted' : id ? 'Updated' : 'Saved'} ${
					activity.description
				} timecard for date ${parsedDate}`;
				if (job) {
					message += ' and project ' + job.name;
				}
				enqueueSnackbar(message, {
					variant: 'info',
					preventDuplicate: true,
					autoHideDuration: 5000,
				});
				handleFinishSubmit();
			} catch (error) {
				enqueueSnackbar(`Unable to ${isSubmit ? 'submit' : id ? 'update' : 'save'} timecard. ${error}`, {
					variant: 'error',
					preventDuplicate: true,
				});
				stopLoading();
				return; // Prevent closing the dialog if there's an error
			} finally {
				stopLoading();
				refreshData();
			}
		},
		handleFileChange = (files) => {
			setSelectedFiles(files);
		},
		handleDeleteAttachment = (attachmentId) => {
			setExistingAttachments(existingAttachments.filter((attachment) => attachment.id !== attachmentId));
		},
		onExit = () => {
			resetTimecardForm();
			onClose();
		},
		onSave = async () => {
			await handleSave(false);
		},
		onSubmit = async () => {
			var hasError = false;
			if (!selectedActivity) {
				setActivityError('Activity is required');
				hasError = true;
			}
			if (!selectedProject) {
				setProjectError('Project is required');
				hasError = true;
			}
			if (!selectedHours) {
				setHoursError('Hours are required');
				hasError = true;
			}
			if (hasError) {
				return;
			}
			setOpenConfirmationDialog(true);
		},
		handleOkConfirmationDialog = async () => {
			await handleSave(true);
		},
		// SAS TOKEN - Downloadable attachments
		[sasToken, setSasToken] = useState(null),
		getSasToken = useCallback(async () => {
			try {
				const { sasToken } = await httpGet({
					urlSubPath: 'sas-token',
					queryParams: { containerName: process.env.REACT_APP_ABS_ATTACHMENTS_CONTAINER_NAME },
				});
				setSasToken(sasToken);
			} catch (error) {
				console.error('Error fetching SAS token:', error);
			}
		}, [httpGet]),
		getAuthenticatedUri = (uri) => {
			return sasToken ? `${uri}?${sasToken}` : uri;
		},
		handleDownload = async (attachment) => {
			const authenticatedUri = getAuthenticatedUri(attachment.uri);
			try {
				const response = await fetch(authenticatedUri),
					blob = await response.blob(),
					url = window.URL.createObjectURL(blob),
					link = document.createElement('a');
				link.href = url;
				link.download = attachment.filename;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				window.URL.revokeObjectURL(url);
			} catch (error) {
				console.error('Error downloading file:', error);
				enqueueSnackbar(`Error downloading file: ${error.message}`, { variant: 'error' });
			}
		};

	// EFFECTS
	useEffect(() => {
		if (open && !hasLoadedOptions) {
			loadActivityOptions();
			loadProjectOptions();
			setHasLoadedOptions(true);
		}

		if (!open) {
			setHasLoadedOptions(false);
		}
	}, [open, hasLoadedOptions, loadActivityOptions, loadProjectOptions]);

	useEffect(() => {
		if (editingTimecard) {
			setId(editingTimecard.id);
			setSelectedDate(dayjs(editingTimecard.timecardDate));
			setSelectedProject(editingTimecard.job);
			setSelectedActivity(editingTimecard.activity);
			setSelectedHours(editingTimecard.hours.toString());
			setSelectedNotes(editingTimecard.notes);
			setExistingAttachments(editingTimecard.attachments || []);
			setIsBillable(editingTimecard.isBillable);
			setSelectedUser(editingTimecard.user);
		} else {
			resetTimecardForm();
		}
	}, [editingTimecard, resetTimecardForm]);

	// Add effect to load users and set default user
	useEffect(() => {
		if (open && !hasLoadedOptions && isAdmin) {
			loadUserOptions();
		}
	}, [open, hasLoadedOptions, loadUserOptions, isAdmin]);

	// NEW: Effect for fetching SAS token
	useEffect(() => {
		if (open && existingAttachments.length > 0) {
			getSasToken();
		}
	}, [open, existingAttachments, getSasToken]);

	const handleDeleteTimecard = async () => {
		if (!window.confirm('Are you sure you want to delete this timecard? This action cannot be undone.')) {
			return;
		}

		startLoading();
		try {
			await httpDelete({ urlSubPath: `timecards/${id}` });
			enqueueSnackbar('Timecard deleted successfully', {
				variant: 'success',
				preventDuplicate: true,
			});
			refreshData();
			onTimecardDeleted?.(id);
			onClose();
		} catch (error) {
			enqueueSnackbar(`Unable to delete timecard. ${error}`, {
				variant: 'error',
				preventDuplicate: true,
			});
		} finally {
			stopLoading();
		}
	};

	return (
		<Dialog open={open} onClose={onClose} fullScreen fullWidth maxWidth="md" scroll="paper">
			<DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				{id ? 'Edit Timecard' : 'Add Timecard'}
			</DialogTitle>
			<DialogContent dividers sx={{ padding: 1 }}>
				<Stack spacing={2}>
					<FormControl fullWidth>
						<DatePicker required value={selectedDate} onChange={handleDateChange} label="Log Date" />
					</FormControl>
					<FormControl fullWidth>
						<Autocomplete
							disablePortal
							size={SIZE.small}
							fullWidth
							options={projectOptions}
							getOptionLabel={(option) => option.name}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							value={selectedProject}
							onChange={(event, newValue) => handleProjectChange(newValue)}
							renderInput={(params) => (
								<TextField
									required
									{...params}
									label="Project"
									error={!!projectError}
									helperText={projectError}
								/>
							)}
						/>
					</FormControl>
					{isAdmin && (
						<FormControl fullWidth>
							<Autocomplete
								disablePortal
								size={SIZE.small}
								fullWidth
								options={userOptions}
								getOptionLabel={(option) => option.name}
								isOptionEqualToValue={(option, value) => option.id === value.id}
								value={selectedUser}
								onChange={(event, newValue) => handleUserChange(newValue)}
								renderInput={(params) => (
									<TextField
										required
										{...params}
										label="User"
										error={!!userError}
										helperText={userError}
									/>
								)}
							/>
						</FormControl>
					)}
					<FormControl fullWidth>
						<Autocomplete
							disablePortal
							size={SIZE.small}
							fullWidth
							options={activityOptions}
							getOptionLabel={(option) => option.description}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							value={selectedActivity}
							onChange={(event, newValue) => handleActivityChange(newValue)}
							renderInput={(params) => (
								<TextField
									required
									{...params}
									label="Activity"
									error={!!activityError}
									helperText={activityError}
								/>
							)}
						/>
					</FormControl>
					{isAdmin && (
						<FormControl fullWidth>
							<Stack direction="row" alignItems="center" spacing={1}>
								<Typography>Is Billable</Typography>
								<Switch
									checked={isBillable}
									onChange={(event) => setIsBillable(event.target.checked)}
									sx={{ minWidth: '75px' }}
								/>
							</Stack>
						</FormControl>
					)}
					<FormControl>
						<TextField
							required
							fullWidth
							label="Hours"
							type="number"
							value={selectedHours}
							onChange={handleHoursChange}
							error={!!hoursError}
							helperText={hoursError}
							inputProps={{
								step: 0.5,
								inputMode: 'decimal',
							}}
						/>
					</FormControl>
					<FormControl fullWidth>
						<TextField
							fullWidth
							multiline
							rows={3}
							label="Notes"
							value={selectedNotes}
							onChange={handleNotesChange}
						/>
					</FormControl>
					<FileUploadInput
						selectedFiles={selectedFiles}
						onFileChange={handleFileChange}
						buttonText="Attach files to timecard"
						allowMultiple={true}
						acceptedFileTypes={['.pdf', '.doc', '.docx', '.jpg', '.png']}
						maxFileSize={50 * 1024 * 1024} // 50 MB
						maxFiles={50}
						showFileList={true}
						fileListStyle={{ color: 'text.secondary' }}
					/>
					{existingAttachments.length > 0 && (
						<List>
							<Typography variant="subtitle1">Existing Attachments:</Typography>
							{existingAttachments.map((attachment) => (
								<ListItem
									key={attachment.id}
									secondaryAction={
										<IconButton
											edge="end"
											aria-label="delete"
											onClick={() => handleDeleteAttachment(attachment.id)}
										>
											<DeleteIcon />
										</IconButton>
									}
								>
									<ListItemText
										primary={
											<Link component="button" onClick={() => handleDownload(attachment)}>
												{attachment.filename}
											</Link>
										}
									/>
								</ListItem>
							))}
						</List>
					)}
					{isAdmin && id && (
						<Button
							onClick={handleDeleteTimecard}
							variant="contained"
							color="error"
							sx={{ mt: 2, width: '200px' }}
						>
							Delete Timecard
						</Button>
					)}
				</Stack>
			</DialogContent>
			<Dialog title="Confirm Timecard Submission" open={openConfirmationDialog}>
				<DialogTitle align="center">Confirm Timecard Submission</DialogTitle>
				<Stack>
					<ListItem divider />
					<DialogContent align="center">
						<Typography align="center" variant="body1" sx={{ fontWeight: 'bold' }}>
							Submit Timecard?
						</Typography>
						<Typography variant="body2">You will not be able to change it</Typography>
					</DialogContent>
					<ListItem divider />
					<DialogActions>
						<Button onClick={handleCloseConfirmationDialog}>No</Button>
						<Button onClick={handleOkConfirmationDialog} color="primary" variant="contained">
							Yes
						</Button>
					</DialogActions>
				</Stack>
			</Dialog>
			<DialogActions>
				<Button onClick={onExit}>Exit</Button>
				<Button onClick={onSave} color="primary">
					Save
				</Button>
				<Button onClick={onSubmit} variant="contained">
					Submit
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default TimecardEntryFormDialog;
