import React from 'react';

import LogsTable from '../logsTable';

const Timecards = ({
	activities,
	columnFilters,
	columnSorts,
	data,
	initialPage,
	initialPageSize,
	onColumnFiltersChange,
	onColumnSortsChange,
	onPaginationChange,
	projects,
	refreshData,
	totalRecordCount,
	users,
}) => {
	return (
		<LogsTable
			activities={activities}
			data={data}
			initialPage={initialPage}
			initialPageSize={initialPageSize}
			inputColumnFilters={columnFilters}
			inputColumnSorts={columnSorts}
			onColumnFiltersChange={onColumnFiltersChange}
			onColumnSortsChange={onColumnSortsChange}
			onPaginationChange={onPaginationChange}
			projects={projects}
			refreshData={refreshData}
			totalRecordCount={totalRecordCount}
			type="timecard"
			users={users}
		/>
	);
};

export default Timecards;
