import React, { useCallback, useContext, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Stack, Tabs, Tab } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';

import { DEFAULT_PAGE_SIZE } from './constants';
import Observations from './observations';
import ObservationEntryFormDialog from './observations/observationEntryFormDialog';
import Timecards from './timecards';
import TimecardEntryFormDialog from './timecards/timecardEntryFormDialog';
import { ROLESDATA } from '../../components/aclContainer/roles';
import AppContainer from '../../components/appContainer';
import { AuthContext, LoadingContext } from '../../context';
import useFetchWithMsal from '../../hooks/useFetchWithMsal';
import { useScreenSize } from '../../hooks/useScreenSize';

function LogsPage() {
	const { size } = useScreenSize(),
		{ httpGet } = useFetchWithMsal(),
		{ canAccess, getUserId } = useContext(AuthContext),
		{ enqueueSnackbar } = useSnackbar(),
		{ startLoading, stopLoading } = React.useContext(LoadingContext),
		location = useLocation(),
		navigate = useNavigate(),
		[tabIndex, setTabIndex] = useState(0),
		[opened, setOpened] = useState([]),
		// TIMECARDS
		[openTimecardDialog, setOpenTimecardDialog] = useState(false),
		[timecardData, setTimecardData] = useState([]),
		[totalTimecardRecords, setTotalTimecardRecords] = useState(0),
		[timecardsPagination, setTimecardsPagination] = useState({
			pageIndex: 0,
			pageSize: DEFAULT_PAGE_SIZE,
			totalRecordCount: 0,
		}),
		[timecardFilters, setTimecardFilters] = useState([]),
		[timecardSorts, setTimecardSorts] = useState([]),
		// OBSERVATIONS
		[openObservationDialog, setOpenObservationDialog] = useState(false),
		[observationData, setObservationData] = useState([]),
		[observationPaginationPage, setObservationPaginationPage] = useState(1),
		[observationPaginationPageSize, setObservationPaginationPageSize] = useState(100),
		[totalObservationRecords, setTotalObservationRecords] = useState(0),
		[observationsPagination, setObservationsPagination] = useState({
			pageIndex: 0,
			pageSize: DEFAULT_PAGE_SIZE,
			totalRecordCount: 0,
		}),
		[observationFilters, setObservationFilters] = useState([]),
		[observationSorts, setObservationSorts] = useState([]),
		[users, setUsers] = useState([]),
		[activities, setActivities] = useState([]),
		[projects, setProjects] = useState([]),
		processFilters = (filters, dateFilterId, sorting) => {
			const queryParams = {},
				userFilter = filters.find((f) => f.id === 'user');
			if (userFilter?.value?.length) {
				queryParams.userIds = userFilter.value;
			}

			const activityFilter = filters.find((f) => f.id === 'activity.description');
			if (activityFilter?.value?.length) {
				queryParams.activityIds = activityFilter.value;
			}

			const projectFilter = filters.find((f) => f.id === 'project');
			if (projectFilter?.value?.length) {
				queryParams.projectIds = projectFilter.value;
			}

			const dateFilter = filters.find((f) => f.id === dateFilterId);
			if (dateFilter?.value) {
				const [startDateFilter, endDateFilter] = dateFilter.value;
				if (startDateFilter) {
					// Convert from DateTime to Timestamp to DateTime
					queryParams.startDate = new Date(new Date(startDateFilter).setHours(0, 0, 0, 0)).toISOString();
				}
				if (endDateFilter) {
					// Convert from DateTime to Timestamp to DateTime
					queryParams.endDate = new Date(new Date(endDateFilter).setHours(23, 59, 59, 999)).toISOString();
				}
			}

			if (canAccess([ROLESDATA.Installer.RoleName])) {
				queryParams.userIds = [getUserId()];
			}

			// Add sorting parameter if exists
			if (sorting?.length > 0) {
				const sortArray = sorting.map((sort) => ({
					field: sort.id,
					order: sort.desc ? 'DESC' : 'ASC',
				}));

				// Convert to URL-safe format
				queryParams.sort = sortArray;
			}

			return queryParams;
		},
		// TIMECARDS
		loadTimecardData = useCallback(() => {
			startLoading();
			const queryParams = processFilters(timecardFilters, 'timecardDate', timecardSorts);

			httpGet({
				urlSubPath: 'v2/timecards',
				queryParams,
				page: timecardsPagination.pageIndex + 1,
				pageSize: timecardsPagination.pageSize,
			})
				.then((response) => {
					setTimecardData(response.rows);
					setTotalTimecardRecords(response.count);
				})
				.catch((error) => enqueueSnackbar(`Unable to load timecards. ${error}`, { variant: 'error' }))
				.finally(() => {
					stopLoading();
				});
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [timecardsPagination, timecardFilters, timecardSorts]),
		handleTimecardsPaginationChange = useCallback(
			(newPagination) => {
				setTimecardsPagination(newPagination);
			},
			[setTimecardsPagination]
		),
		handleClickLogTimecard = () => {
			setOpenTimecardDialog(true);
		},
		handleCloseTimecardDialog = () => {
			setOpenTimecardDialog(false);
		},
		// OBSERVATIONS
		loadObservationData = useCallback(() => {
			startLoading();
			const queryParams = processFilters(observationFilters, 'observationDate', observationSorts);

			httpGet({
				urlSubPath: 'v2/observations',
				queryParams,
				page: observationsPagination.pageIndex + 1,
				pageSize: observationsPagination.pageSize,
			})
				.then((response) => {
					setObservationData(response.rows);
					setTotalObservationRecords(response.count);
				})
				.catch((error) => enqueueSnackbar(`Unable to load observations. ${error}`, { variant: 'error' }))
				.finally(() => {
					stopLoading();
				});
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [observationsPagination, observationFilters, observationSorts]),
		handleClickLogObservation = () => {
			setOpenObservationDialog(true);
		},
		handleCloseObservationDialog = () => {
			setOpenObservationDialog(false);
		},
		handleObservationsPaginationChange = (newPagination) => {
			if (newPagination.page !== observationPaginationPage) {
				setObservationPaginationPage(newPagination.page);
			}
			if (newPagination.pageSize !== observationPaginationPageSize) {
				setObservationPaginationPageSize(newPagination.pageSize);
			}
			setObservationsPagination(newPagination);
		};

	// EFFECTS
	useEffect(() => {
		loadTimecardData();
	}, [loadTimecardData]);
	useEffect(() => {
		loadObservationData();
	}, [loadObservationData]);

	useEffect(() => {
		const openedString = location.search.split('opened=')[1]?.split('&')[0];
		if (openedString) {
			const openedFromUrl = openedString.split('_').map(Number);
			setOpened(openedFromUrl);

			// Add this to ensure URL is updated on mount
			const searchParams = new URLSearchParams(window.location.search);
			searchParams.set('opened', openedString);
			const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
			window.history.replaceState(null, '', newUrl);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // Run only on mount

	useEffect(() => {
		const expectedPath =
				tabIndex === 0
					? `/timecards?page=${timecardsPagination.pageIndex + 1}&pageSize=${timecardsPagination.pageSize}`
					: // eslint-disable-next-line max-len
						`/observations?page=${observationsPagination.pageIndex + 1}&pageSize=${observationsPagination.pageSize}`,
			path = location.pathname;

		if (location.pathname + location.search !== expectedPath) {
			navigate(expectedPath, { replace: true });
		} else if (path.includes('/timecards') && tabIndex !== 0) {
			setTabIndex(0);
		} else if (path.includes('/observations') && tabIndex !== 1) {
			setTabIndex(1);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, tabIndex]);

	useEffect(() => {
		const fetchFilterData = async () => {
			try {
				// Fetch users
				const usersResponse = await httpGet({ urlSubPath: 'users' });
				setUsers(usersResponse.map(({ id, name }) => ({ id, name })));

				// Fetch all activities (both types)
				const activitiesResponse = await httpGet({ urlSubPath: 'activities' });
				setActivities(activitiesResponse);

				// Fetch all projects
				const projectsResponse = await httpGet({ urlSubPath: 'job' });
				setProjects(projectsResponse);
			} catch (error) {
				console.error('Error fetching filter data:', error);
			}
		};

		fetchFilterData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // Run only on mount

	return (
		<AppContainer>
			<Box
				sx={{
					display: 'flex',
					flexDirection: { xs: 'column', md: 'row' },
					alignItems: { xs: 'stretch', md: 'center' },
					justifyContent: 'space-between',
				}}
			>
				<Tabs
					value={tabIndex}
					onChange={(event, newValue) => setTabIndex(newValue)}
					sx={{
						minHeight: { xs: 48, md: 'auto' },
						'& .MuiTab-root': {
							minHeight: { xs: 48, md: 'auto' },
							py: { xs: 1, md: 'auto' },
							width: { xs: '50%', md: 'auto' },
							minWidth: { md: 250 },
						},
						display: { xs: 'flex', md: 'inline-flex' },
						width: { xs: '100%', md: 'auto' },
						order: { xs: 2, md: 1 },
						bgcolor: { xs: 'background.paper', md: 'transparent' },
					}}
				>
					<Tab label="Timecards" />
					<Tab label="Observations" />
				</Tabs>
				<Stack
					spacing={2}
					direction="row"
					sx={{
						width: { xs: '100%', md: 'auto' },
						order: { xs: 1, md: 2 },
						px: { xs: 1, md: 0 },
						pt: { xs: 1, md: 0 },
						mr: { md: '15px' },
					}}
				>
					<Button
						variant="contained"
						size={size}
						startIcon={<AddIcon />}
						onClick={handleClickLogTimecard}
						sx={{ flexGrow: { xs: 1, md: 0 }, minWidth: { xs: 160, md: 180 } }}
					>
						Timecard
					</Button>
					<Button
						variant="contained"
						size={size}
						startIcon={<AddIcon />}
						onClick={handleClickLogObservation}
						sx={{ flexGrow: { xs: 1, md: 0 }, minWidth: { xs: 160, md: 180 } }}
					>
						Observation
					</Button>
				</Stack>
			</Box>
			<Box>
				{tabIndex === 0 && (
					<Timecards
						activities={activities}
						columnFilters={timecardFilters}
						columnSorts={timecardSorts}
						data={timecardData}
						initialPage={timecardsPagination.pageIndex}
						initialPageSize={timecardsPagination.pageSize}
						onColumnFiltersChange={setTimecardFilters}
						onColumnSortsChange={setTimecardSorts}
						onPaginationChange={handleTimecardsPaginationChange}
						opened={opened}
						projects={projects}
						refreshData={loadTimecardData}
						setOpened={setOpened}
						totalRecordCount={totalTimecardRecords}
						users={users}
					/>
				)}
				{tabIndex === 1 && (
					<Observations
						activities={activities}
						columnFilters={observationFilters}
						columnSorts={observationSorts}
						data={observationData}
						initialPage={observationsPagination.pageIndex}
						initialPageSize={observationsPagination.pageSize}
						onColumnFiltersChange={setObservationFilters}
						onColumnSortsChange={setObservationSorts}
						onPaginationChange={handleObservationsPaginationChange}
						opened={opened}
						projects={projects}
						refreshData={loadObservationData}
						setOpened={setOpened}
						totalRecordCount={totalObservationRecords}
						users={users}
					/>
				)}
			</Box>
			<TimecardEntryFormDialog
				open={openTimecardDialog}
				onClose={handleCloseTimecardDialog}
				refreshData={loadTimecardData}
				opened={opened}
				setOpened={setOpened}
			/>
			<ObservationEntryFormDialog
				open={openObservationDialog}
				onClose={handleCloseObservationDialog}
				refreshData={loadObservationData}
				opened={opened}
				setOpened={setOpened}
			/>
		</AppContainer>
	);
}

export default LogsPage;
